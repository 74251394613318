@import 'fonts.scss';
@import 'global.scss';

@mixin flex-center {
    // write the css here
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

*{
    font-family: 'Arapey', Arial, Helvetica, sans-serif;
}
h1,h2,h3,h4{
    font-weight: normal;
}

.hidden {
    display: none;
}

#main-ui{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
body main{
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}

main section{
    position: absolute;
    width: 100%;
    height: 100%;
    .background, .content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .background{
        .new,.old{
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

main section#project-detail{
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 10%;
    background: white;
    padding-bottom: 150px;
    
    .fade-in{
        opacity: 0;
    }
    .project{
        width: 100%;
        opacity: 1;
        span{
            color: #2D2B2C;
            font-style: italic;
            font-size: 24px;

            @media only screen 
            and (max-device-width: 667px) 
            and (-webkit-min-device-pixel-ratio: 2){
                font-size:  44px;
            } 
        }

        .copy{
            //padding-right: 140px;
            height: 100%;
            p{
                font-size: 26px;
                padding: 0;
                word-break: break-word;

                @media (min-width: 1921px) {
                    font-size: 34px;
                }
                
                @media (max-width: 1920px) {
                    font-size: 26px;
                    
                }
        
                @media (max-width: 1600px) {
                    font-size: 22px;
                }
                @media only screen 
                and (max-device-width: 667px) 
                and (-webkit-min-device-pixel-ratio: 2){
                    font-size:  40px;
                } 
                @media (max-width: 480px){
                    font-size: 25px;
                }  
            }

            @media only screen 
            and (max-device-width: 667px) 
            and (-webkit-min-device-pixel-ratio: 2){
                .tag{
                    text-align: left !important;
                }
            } 
            

            // span{
            //     text-align: right;
            //     display: block;
            //     margin-right: 10px;
            // }
        }

        p{
            font-family: 'DarkerGrotesque-Regular', Arial, Helvetica, sans-serif;
        }
        h1{
            font-size: 152px;
            margin-bottom: 0;
            // font-size: 152px;
            font-size: 6vw;
            
            @media (max-width: 1920px) {
                // font-size: 132px;
                font-size: 5.7vw;
                
            }
    
            @media (max-width: 1600px) {
                // font-size: 112px;
                font-size: 5.3vw;
            }
            @media only screen 
            and (max-device-width: 667px) 
            and (-webkit-min-device-pixel-ratio: 2) {
                font-size: 80px;  
            }
            
        }
        article{
            width: 100%;
            figure img{
                width: 100%;
            }
        }

        .color-pallet {
            .color-wrapper{
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
                .color {
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    margin: 5px;
                }
            }
            .pallet-label{
                text-align: right;
            }
            @media only screen 
            and (max-device-width: 667px) 
            and (-webkit-min-device-pixel-ratio: 2)  {
                padding-top: 30px;
                padding-bottom: 50px;
                .color-wrapper{
                    justify-content: center;
                }
                .pallet-label{
                    text-align: center;
                }
            }
        }
    }
    .topics{
        text-align: center;
        display: block;
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2){
            font-size: 24px !important;
        } 
    }
    .project-nav{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        // color: #2D2B2C;
        // font-style: italic;
        font-size: 30px;
        margin-top: 30px;
        @media only screen 
        and (max-device-width: 667px) 
        and (-webkit-min-device-pixel-ratio: 2){
            margin-bottom: 30px;
            font-size:  50px;
        } 

        a{
            
            color: black;
            font-family:  'Arapey', Arial, Helvetica, sans-serif;
            margin: 0 10px;
        }
    }
}

footer{
    position: absolute; 
    left: 0;
    bottom: 0;
    width: 100%;
}
#social-links{
    position: absolute;
    @include flex-center;
    
    left: 27px;
    bottom: 60px;
    .dark{
        display: none;
    }
    .light{
        display: block;
    }
    a{
        width: 24px;
        margin: 0 30px;
        img{
            width: 100%;
        }
    }

    @media (max-height: 800px) {
        bottom: 30px;
        a{
            width: 20px;
        }
    }
    @media (max-width: 480px){
        bottom: 30px;
        transform: translateX(-50%);
        left: 50%;
        a{
            width: 20px;
            margin: 0 20px;
        }
    }
    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        a{
            width: 40px;
            margin: 0 30px;
        }
    }
}

#copy-right{
    color: white;
    position: absolute;
    right: 50px;
    bottom: 10px;
    font-size: 24px;
    &.dark-theme{
        color: #3b3b3b;
    }
    @media (max-height: 800px) {
        bottom: 10px;
    }

    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2) {
        font-size: 34px;
        bottom: 30px;
    }
}

#social-links.dark-theme{
    .dark{
        display: block;
    }
    .light{
        display: none;
    }
}

@import 'landing.scss';
@import 'about.scss';
@import 'nav.scss';
@import 'elbohemio.scss';
@import 'changeoflight.scss';
@import 'project-gallery.scss';
@import 'caleidoscope.scss';
@import 'in-no-memory.scss';