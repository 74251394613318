nav{
    //background: blue;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 24px 56px;
    // padding-top: 24px;
    #nav-logos{
        width: 49px;
        height: 55px;
        display: block;
        img{
            width: 100%;
        }
        img#nav-logo-negro{
            display: none;
        }
        
        img#nav-logo-blanco{
            display: block;
        }
    }
    .nav-links{
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 200px;
        a{
            font-size: 24px;
            letter-spacing: 1px;
            font-style: italic;
            color: white;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
            margin-left: 126px;
        }
    }
    @media (max-height: 800px) {
        padding-top: 30px;
    }
    @media (max-width: 480px) {
        .nav-links{
            right: 30px;
            .link{
                margin: 0 10px;
            }
        }
    }
    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2){
        .nav-links{
            right: 56px;
            a{
                font-size: 44px;
            }
        }
    }
}

nav.dark-theme{
    background-color: rgba(255,255,255,0.8);
    backdrop-filter: blur(12px);
    .nav-links a{
        color: #3b3b3b;
    }
    #nav-logos{
        img#nav-logo-negro{
            display: block;
        }
        
        img#nav-logo-blanco{
            display: none;
        }
    }
}