/* Webfont: Boing */
$fa-font-path: '';
@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Italic.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


@font-face {
    font-family: 'DarkerGrotesque-medium';
    src: url('../fonts/DarkerGrotesque-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'DarkerGrotesque-regular';
    src: url('../fonts/DarkerGrotesque-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

