#caleidoscope{
    padding-top: 190px;

    article{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;        

        .copy{
            width: 40%;
            .title{
                margin-top: 0;
            }
        }
        
        .images-wrapper{
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            figure{

                width: calc(50% - 30px);
                margin: 15px;

                &:last-child{
                    width: 100%;
                }
            }
        }
    }

    @media  only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2)  {
        article{
            flex-wrap: wrap;
            .copy, .images-wrapper{
                width: 100%;
            }
        }
    }
}