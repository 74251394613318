
@mixin flex-center {
    // write the css here
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin center {
    // write the css here
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
}
#landing{
    .background{
        .old, .new{
            background-image: url('../../static/assets/images/home_1.jpg');
            background-size: cover;
        }
        .new{
            background-image: url('../../static/assets/images/home_2.jpg');
        }
    }
    #landing-nav{
        @include center;
        @include flex-center;

        // width: 700px;
        width: 50%;
        // height: 400px;
        #logo-landing{
            width: 35%;
            // width: 400px;
            // height: 400px;
        }
        
        .container{
            position: absolute;
            left: 15%;
            @include flex-center;
            flex-direction: column;
            opacity: 0;
            pointer-events: none;
            transition: all 0.4s;
            a{
                font-size: 26px;
                margin-bottom: 40px;
                color:white;
                text-decoration: none;
                &:hover{
                    border-bottom: 1px solid white;
                }
             
            }
        }
        .instruction{
            position: absolute;
            color: white;
            margin: 0;
            font-size: 26px;
            letter-spacing: 2px;
            left: 9%;
            bottom: -10px;
            transition: all 0.4s;
        }
        
        &:hover{
            .container{
                opacity: 1;
                pointer-events: all;
            }
            
            .instruction{
                opacity: 0;
            }
        }
       
    }
    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2){
        #landing-nav{
            .container{
                opacity: 1;
                pointer-events: all;
                left: -60px;
                a{
                    font-size: 56px;
                }
            }
            
            .instruction{
                opacity: 0;
            }
            
            #logo-landing{
                width: 45%;
            }
        }
    }
}