#about{
    .background{
        .old, .new{
            background-image: url('../../static/assets/images/about_1.jpg');
            background-size: cover;
        }
        .new{
            background-image: url('../../static/assets/images/about_2.jpg');
        }
    }
    .content{
       
        
       
        h1{

            color: white;
            font-weight: 100;
            letter-spacing: 1px;
            font-size: 78px;
            opacity: 0;
            
            position: absolute;
            &.c_1{
                top: 304px;
                right: 208px;
            }
            &.c_2{
                left: 90px;
                bottom: 225px;
            }
            &.c_3{
                right: 226px;
                bottom: 225px;
            }
            &.c_4{
                right: 190px;
                bottom: 350px;
            }
            &.c_5{
                right: 158px;
                bottom: 170px;
            }
        }
        @media (min-width: 1921px) {
            h1{
                font-size: 93px;
            }
        }
        
        @media (max-width: 1920px) {
            h1{
                font-size: 78px;
            }
        }

        @media (max-height: 800px) {
            h1{
                font-size: 68px;
                
                &.c_1{
                    top: 344px;
                    right: 208px;
                }
                &.c_2{
                    left: 90px;
                    bottom: 235px;
                }
                &.c_3{
                    right: 226px;
                    bottom: 225px;
                }
                &.c_4{
                    right: 190px;
                    bottom: 300px;
                }
                &.c_5{
                    right: 158px;
                    bottom: 190px;
                }
            }
        }
    }
}