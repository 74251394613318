#projects{
    .background{
        .carousel-bg{
            z-index: 0;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0;
            transition: opacity 0.6s ease-in-out;
            &#p_0{
                background-image: url("../../static/assets/images/galeria_1.jpg");
            }
            &#p_1{
                background-image: url("../../static/assets/images/galeria_2.jpg");
            }
            &#p_2{
                background-image: url("../../static/assets/images/galeria_3.jpg");
            }
            &#p_3{
                background-image: url("../../static/assets/images/galeria_4.jpg");
            }
            &#p_4{
                background-image: url("../../static/assets/images/galeria_5.jpg");
            }
            &.show{
                opacity: 1;
            }
        }
    }
    .projects-list{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 500px;
        margin: 0 auto;
        h1, h2,h3 ,p{
            color: white;
        }
    }
    .project-link{
        cursor: pointer !important;
        display: flex;
        width: auto;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        .title{
            text-align: center;
        }
        .info{
            opacity: 0;
            position: absolute;
            padding-left: 40px;
            transition: opacity 300ms;
        }
        .title, .info{
            width: 200px;
        }
        &.selected{
            .info{
                opacity: 1;
            }
            .title:after{
                content: ' ';
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: -5px;
                border-bottom: 2px solid ;
            }
            .info::after{
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                transform: translate(-50%, -50%) rotate(45deg);
                left: 20px;
                top: 50%;
            }
            &.bohemio{
                .title:after{
                    border-color: #a92626;
                }
                .info::after{
                    background-color: #a92626;
                }
            }
            &.change-of-light{
                .title:after{
                    border-color: black;
                }
                .info::after{
                  background-color: black;
                }
            }
            &.in-no-memory{
                .title:after{
                    border-color: white;
                }
                .info::after{
                  background-color: white;
                }
            }
            &.caleidoscope{
                .title:after{
                    border-color: #39b548;
                }
                .info::after{
                    background-color:  #39b548;
                }
            }
        }
       
    }
    .project-link,   .projects-list{
        transition: width 600ms;
    }

    @media only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2){
        
        .title{
            font-size: 64px;
            width: auto !important;
        }
        .projects-list{
            width: 100%;
        }
        .project-link .info{
            display: none;
        }
    }
}