#elbohemio{
    padding-top: 190px;
    article{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        
        &.project-header{
            align-items: end;
        }
        
        figure{
            flex-shrink: 1;
            flex-shrink: 1;
            margin: 1%;
            width: 100%;
        }
        
        p{
            font-size: 24px;
            padding: 4%;
            word-break: break-word;
        }

        h1{
            margin: 0;
        }
        
        .copy{
            padding-right: 140px;
            height: 100%;
            
            span{
                // text-align: right;
                display: block;
                margin-right: 10px;
            }
        }
    }
   
    @media  only screen 
    and (max-device-width: 667px) 
    and (-webkit-min-device-pixel-ratio: 2)  {
        padding-top: 120px;
        .project-header,  .block-with-copy{
            flex-wrap: wrap;
            p, .copy, figure{
                padding: 0;
                width: 100% !important;
            }
            p{
                text-align: center;
            }
            .tag{
                text-align: left;
                margin-bottom: 40px;
            }
        }
        p{
            font-size: 40px !important;
        }
    }
}